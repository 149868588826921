import React, { useState } from "react";
import { promocodeupdate } from "../../services/api";
import { Modal, Button } from "react-bootstrap";

function formatUTCtoIST(utcTimestamp) {
  const istTime = new Date(utcTimestamp.toLocaleString("en-US", { timeZone: "Asia/Kolkata" }));
  return istTime.toLocaleString();
}

function Promocodeposts({ posts }) {
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");

  const confirm = async (i) => {
    await promocodeupdate(i._id, i.data);
    window.location.reload();
  };

  const handleViewTandC = (content) => {
    setModalContent(content);
    setShowModal(true);
  };

  return (
    <>
      {posts &&
        posts.map((i, index) => (
          <tr key={i._id}>
            <td>
              <span>{index + 1}</span>
            </td>
            <td>
              <span>{i.codename}</span>
            </td>
            <td>
              <span>{i.message}</span>
            </td>
            <td>
              <span>{i.discount}</span>
            </td>
            <td>
              <span>{i.type}</span>
            </td>
            <td>
              <span>{formatUTCtoIST(new Date(i.started))}</span>
            </td>
            <td>
              <span>{formatUTCtoIST(new Date(i.ended))}</span>
            </td>
            <td>
              <span>{i.mini}</span>
            </td>
            <td>
              <span>{i.noofusers}</span>
            </td>
            <td>
              <button
                className="btn btn-info btn-sm"
                onClick={() => handleViewTandC(i.TandC)}
              >
                View
              </button>
            </td>

            <td>
              <span>
                {i.currentstatus ? <span>True</span> : <span>False</span>}
              </span>
            </td>

            <td>
              <button
                className="btn btn-dark btn-lg"
                onClick={(e) => {
                  confirm(i);
                }}
              >
                <i
                  className={
                    !i.currentstatus ? "fa-solid fa-toggle-off" : "fa-solid fa-toggle-on"
                  }
                ></i>
              </button>
            </td>
          </tr>
        ))}

      {/* Modal for viewing Terms and Conditions */}
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: modalContent }} />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Promocodeposts;