import { React, useState, useEffect } from "react";
import { confirmvendor, vandorapproved } from "../../services/api";
import Footer from "../footer/Footer";
import Vendorsconfirmedposts from "./Vendorsconfirmedposts";
import Pagination from "../categories/categories/Pagination";

function ProfileUpates() {
  const [searchedvalue, setsearchedvalue] = useState("");
  const [posts, setposts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(25);
  const [sortOrder, setSortOrder] = useState("asc"); // Initial state for sorting order

  // Initialize sort order from localStorage
  useEffect(() => {
    const savedSortOrder = localStorage.getItem("sortOrder");
    if (savedSortOrder) {
      setSortOrder(savedSortOrder);
    }
  }, []);

  // Fetch data on initial render
  useEffect(() => {
    async function data() {
      let dat = await vandorapproved();
      setposts(dat);
    }
    data();
  }, []);

  // Sort posts by date based on the current sort order
  const sortPostsByDate = (order) => {
    const sortedPosts = [...posts].sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return order === "asc" ? dateA - dateB : dateB - dateA;
    });
    setposts(sortedPosts);
  };

  // Handle sort button click
  const handleSortClick = () => {
    setSortOrder((prevOrder) => {
      const newOrder = prevOrder === "asc" ? "desc" : "asc";
      localStorage.setItem("sortOrder", newOrder); // Save sort order to localStorage
      sortPostsByDate(newOrder);
      return newOrder;
    });
  };

  // Get current posts
  const Totalpages = Math.ceil(posts?.length / postsPerPage);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = posts.slice(indexOfFirstPost, indexOfLastPost);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <div className="content-page">
        <div className="content">
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Vendors</h4>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-xl-12">
                <div className="card m-b-30">
                  <div className="card-body">
                    <h4 className="mt-0 header-title mb-4">
                      Confirmed Vendor List
                    </h4>
                    <form
                      className="d-flex mb-2 justify-content-between"
                      style={{ width: "100%" }}
                      role="search"
                    >
                      <div className="btn-group">
                        <input
                          className="form-control mx-2 btn-close"
                          type="search"
                          placeholder="Search name"
                          value={searchedvalue}
                          onChange={(e) => {
                            setsearchedvalue(e.target.value);
                          }}
                          aria-label="Search"
                        />
                        <button
                          type="button"
                          className="btn bg-transparent border-none"
                          style={{ left: "-43px" }}
                          onClick={async () => {
                            setsearchedvalue("");
                            let dat = await vandorapproved();
                            setposts(dat);
                          }}
                        >
                          <i
                            className="fa fa-times"
                            style={{ color: "white" }}
                          ></i>
                        </button>

                        <button
                          className="btn rounded btn-md btn-outline-secondary btn-dark"
                          type="submit"
                          onClick={async (e) => {
                            e.preventDefault();
                            if (searchedvalue) {
                              let dat = await confirmvendor(searchedvalue);
                              setposts(dat.data);
                            } else {
                              let dat = await vandorapproved();
                              setposts(dat);
                            }
                          }}
                        >
                          Search
                        </button>
                      </div>
                      <button
                        className="btn btn-outline-primary ml-2 text-white"
                        onClick={handleSortClick}
                      >
                        Sort by Date:{" "}
                        {sortOrder === "asc" ? "Ascending" : "Descending"}
                      </button>
                    </form>

                    <div className="table-responsive">
                      <table className="table table-hover">
                        <thead>
                          <tr>
                            <th scope="col">Sr No.</th>
                            <th scope="col">Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone No.</th>
                            <th scope="col">Pin Code</th>
                            <th scope="col">GST</th>
                            <th scope="col">Address</th>
                            <th scope="col">Date</th>
                            <th scope="col" colSpan="2">
                              Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <Vendorsconfirmedposts posts={currentPosts} />
                        </tbody>
                      </table>
                    </div>
                    <Pagination
                      postsPerPage={postsPerPage}
                      totalPosts={Totalpages}
                      paginate={paginate}
                      currentPage={currentPage}
                      pageNumbers={[...Array(Totalpages + 1).keys()].slice(1)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </>
  );
}

export default ProfileUpates;
